import React from "react";

const testPage = () => {
  return (
    <div>
      <a href="https://nuff.london/places/8">Click me you cunt</a>
    </div>
  );
};

export default testPage;
